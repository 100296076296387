<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row space-y-4 lg:space-y-0 block lg:flex flex-col md:flex-row justify-between align-items-center mr-1 ml-1 mt-2">
				<div class="block lg:flex space-x-0 lg:space-x-4 w-full align-items-center rounded-md text-xs md:text-xl">
					<router-link
						:to="{ name: 'productarticles', params: { company_id: $route.params.company_id } }"
						:class="routerLinkClass"
						:style="routerLinkStyle"
					>
						<span style="margin: auto; display: table">
							{{ $t("toWebshop") }}
						</span>
						<span class="ml-2">></span>
					</router-link>
					<input
						type="text"
						v-on:input="onSearchBlog"
						v-model="searchValue"
						:class="inputClass"
						:style="inputStyle"
						v-bind:placeholder="$t('search')"
					/>
				</div>
			</div>

			<div class="row no-gutters space-y-4 lg:space-y-0 mt-3">
				<div class="col-md-12 col-lg-9">
					<div class="row lg:mr-3 mb-2">
						<div class="col-md-12" v-if="isPinTop === true">
							<div
								class="h-48 rounded-md shadow-md"
								:style="
									pinToTopPost && pinToTopPost.banner_color
										? 'background:' +
										  pinToTopPost.banner_color
										: ''
								"
							>
								<div
									class="row cursor-pointer"
									v-on:click="
										redirectUri({
											name: 'singleBlog',
											params: {
												company: $route.params.id,
												id: pinToTopPost.id,
											},
										})
									"
								>
									<div class="h-48 col-md-6">
										<v-lazy-image
											class="img-fluid"
											:src="pinToTopPost.image"
											style="height: 100%"
										/>
									</div>
									<div class="col-md-6">
										<div class="pt-3 pr-3">
											<p
												class="text-white font-semibold pt-5"
											>
												{{ pinToTopPost.title }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-loading="loading"
						class="lg:mr-3"
						:class="{
							'grid grid-cols-1 gap-2 tablet:grid-cols-3 laptop:grid-cols-2 desktop:grid-cols-3 mx-auto':
								GET_BLOG_POST.data.length === 2,
							'mx-auto row': GET_BLOG_POST.data.length < 2,
							'grid grid-cols-1 gap-2 tablet:grid-cols-2 laptop:grid-cols-2 desktop:grid-cols-3':
								GET_BLOG_POST.data.length > 2,
						}"
					>
						<div
							class="no-deco mb-3 cursor-pointer"
							:class="{
								'col-md-4 mx-auto':
									GET_BLOG_POST.data.length < 2,
							}"
							v-on:click="
								redirectUri({
									name: 'singleBlog',
									params: {
										company: $route.params.id,
										id: blog.id,
									},
								})
							"
							v-for="blog in GET_BLOG_POST.data"
							:key="blog.id"
						>
							<div
								class="flex flex-col bg-white rounded-md shadow-md blog-card"
							>
								<div class="p-2">
									<v-lazy-image
										class="img-fluid"
										:src="blog.image"
										style="height: 200px; margin: auto"
									/>
									<div
										class="p-1 mt-3 text-center blog_title"
										:title="showTruncatedTitle(blog.title)"
										v-html="truncate(blog.title, 81, '...')"
									></div>
									<p class="text-center">
										{{ blog.date_published }}
									</p>
								</div>
								<div
									class="blog-content mb-5"
									v-html="
										cleanMessage(
											truncate(blog.content, 100, '...')
										)
									"
								></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<span
								class="flex justify-between align-items-center space-x-5 mt-4"
							>
								<span class="text-base">{{ $t("show") }}</span>
								<span>
									<select
										v-model="limit"
										class="form-select"
										@change="onChangeBlogLimit"
									>
										<option value="3">3</option>
										<option value="6">6</option>
										<option value="9">9</option>
										<option value="12">12</option>
									</select>
								</span>
							</span>
						</div>
					</div>
				</div>
				<!-- <div class="col-lg-1"></div> -->
				<div class="col-md-12 col-lg-3">
					<div
						class="flex flex-col justify-between bg-white border rounded-md p-3 justify-between space-y-4"
					>
						<div class="flex flex-col space-y-5 p-3 rounded-md">
							<span class="font-semibold text-lg text-gray-900">{{
								$t("archives")
							}}</span>
							<div class="flex flex-col">
								<ul class="space-y-2">
									<li
										class="flex align-items-center space-x-4 cursor-pointer"
										v-on:click="
											blogFilter('month', archive.month)
										"
										v-for="(archive, key) in GET_ARCHIVES"
										:key="key"
									>
										<span class="text-gray-900"
											>{{
												$t(
													`${archive.month_str.toLowerCase()}`
												)
											}}
											{{ archive.year }} ({{
												archive.count
											}})</span
										>
									</li>
								</ul>
							</div>
						</div>
						<div class="flex flex-col space-y-5 p-3 rounded-md">
							<span class="font-semibold text-lg text-gray-900">{{
								$t("categories")
							}}</span>
							<div class="flex flex-col">
								<ul
									class="space-y-2"
									v-if="GET_BLOG_CATEGORY.data"
								>
									<li
										class="flex align-items-center space-x-4 cursor-pointer"
										v-on:click="
											blogFilter(
												'category',
												category.name
											)
										"
										v-for="category in GET_BLOG_CATEGORY.data"
										:key="category.id"
									>
										<span class="text-gray-900"
											>{{ category.name }} ({{
												category.post_counts
											}})</span
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="flex flex-col md:flex-row justify-end mb-4">
					<!-- <span class="flex justify-between align-items-center space-x-5 mt-4">
						<span class="text-base">{{ $t('show') }}</span>
						<span>
							<select v-model="limit" class="form-select" @change="shopResultNumber">
								<option value="10" selected>10</option>
								<option value="50">50</option>
								<option value="100">100</option>
								<option value="200">200</option>
							</select>
						</span>
					</span> -->
					<div class="pagination-block" v-if="GET_BLOG_POST.data">
						<!--GET_ARTICLES.total_pages--->
						<paginate
							:page-count="GET_BLOG_POST.total_pages"
							:click-handler="changePageNumber"
							prev-text="<"
							next-text=">"
							:container-class="'pagination space-x-2'"
						>
						</paginate>
					</div>
				</div>
			</div>
		</div>
		<!-- <Notification /> -->
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import VLazyImage from "v-lazy-image/v2";
import debounce from "lodash.debounce";
import i18n from "@/plugins/i18n";

export default {
	name: "BlogList",
	components: {
		Paginate,
		VLazyImage,
	},
	data() {
		return {
			title: "",
			searchValue: "",
			datePublish: "",
			processing: false,
			loading: true,
			more: false,
			limit: 3,
			company_id: null,
			filterValue: "",
			stateValue: "",
			pinToTopPost: {},
			limitName: "blogLimitEnum",
			isPinTop: false,
		};
	},

	computed: {
		...mapGetters({
			GET_BLOG_CATEGORY: "customer/GET_BLOG_CATEGORY",
			GET_BLOG_POST: "customer/GET_BLOG_POST",
			GET_ARCHIVES: "customer/GET_ARCHIVES",
			GET_COMPANY_SUBSCRIPTION_SETTING:"portal/GET_COMPANY_SUBSCRIPTION_SETTING",
		}),
		routerLinkClass() {
			this.systemMode = process.env.VUE_APP_SYSTEM_MODE;
			return this.systemMode === 'whitelabel'
				? 'flex p-2 justify-between uppercase w-full lg:w-9/12 align-items-center space-x-0 lg:space-x-4 no-deco mb-[10px] lg:mb-[0px] rounded font-semibold'
				: 'flex p-2 justify-between uppercase w-full lg:w-9/12 align-items-center space-x-0 lg:space-x-4 no-deco mb-[10px] lg:mb-[0px] rounded font-semibold bg-black text-md text-white';
		},
		routerLinkStyle() {
			return this.systemMode === 'whitelabel'
				? `background: ${this.GET_COMPANY_SUBSCRIPTION_SETTING.button_color || '#E8B105'}; color: white;`
				: '';
		},
		inputClass() {
			return 'flex justify-between align-items-center w-full lg:w-3/12 space-x-4 focus:outline-none border px-3 py-2 rounded-md';
		},
		inputStyle() {
			return this.systemMode === 'whitelabel'
				? `background: ${this.GET_COMPANY_SUBSCRIPTION_SETTING.input_bg_color || 'white'}; color: ${this.GET_COMPANY_SUBSCRIPTION_SETTING.input_text_color || 'black'};`
				: 'background: white; color: gray;';
		},
	},
	mounted() {
		const { company_id } = this.$route.params;
		

		if (company_id && company_id !== "invalid_company") {
			this.company_id = company_id;
			this.limit = window.localStorage.getItem("limitName")
				? window.localStorage.getItem("limitName")
				: 3;
			window.localStorage.setItem("limitName", this.limit);
			if (
				this.$route.query.category !== "" &&
				this.$route.query.category !== undefined &&
				this.$route.query.category !== null
			) {
				this.blogFilter("category", this.$route.query.category);
			} else if (
				this.$route.query.month !== "" &&
				this.$route.query.month !== undefined &&
				this.$route.query.month !== null
			) {
				this.blogFilter("month", this.$route.query.month);
			} else {
				this.getBlogsList(company_id);
			}
	


		    this.getBlogCategory(company_id);
			this.getBlogArchive(company_id);
			this.getBlogBanner();
			this.getBuyerAccountSettings().then(() => {
			this.updateLocaleBasedOnSettings();
		});

		} else {
		this.$services.helpers.notification(
			this.$t("no_company_text"),
			"error",
			this
		);
		}
	},
	methods: {
		getBuyerAccountSettings() {
			return this.$store
				.dispatch("customer/getCompanyInfo")
				.then((resp) => {
				this.customerSetting = resp.data.data;
				});
			},

		changeLocale(locale) {
			i18n.locale = locale;
			this.$services.moment.locale(locale);
			this.$store.commit("utility/CHANGE_LOCALE", locale);
			},

		updateLocaleBasedOnSettings() {
			if (this.customerSetting.apply_english_on_all_templates) {
				this.changeLocale("en");
			}
		},

		getCompanyDetails(company_id) {
			this.$store
			.dispatch("customer/getCompanySettings", { id: company_id })
			.then((res) => {
				if ("is_active" in res.data.data) {
				if (res.data.data.is_active === false) {
					this.$router.push({
					name: "customerdash",
					});
				}
				}
			});
		},
		
		showTruncatedTitle(title) {
			if (title) {
				if (title.length > 81) {
					return title;
				} else {
					return "";
				}
			} else {
				return "";
			}
		},
		onChangeBlogLimit: function () {
			window.localStorage.setItem("limitName", this.limit);
			this.getBlogsList(this.company_id, this.limit);
		},
		changePageNumber(number) {
			let pageUrl = `?company_id=${this.company_id}&limit=${this.limit}&page=${number}`;
			if (this.stateValue !== "") {
				if (this.stateValue === "category") {
					pageUrl = pageUrl + `&category=${this.filterValue}`;
				}
				if (this.stateValue === "month") {
					pageUrl =
						pageUrl +
						`&date_from=${
							this.filterValue
						}&date_to=${this.calculateMonthEnd(this.filterValue)}`;
				}
			} else {
				this.stateValue = "";
				this.filterValue = "";
			}
			this.loading = true;
			this.$store
				.dispatch("customer/getBlogs", { url: pageUrl })
				.then((_) => {
					this.loading = false;
				})
				.catch((_) => {
					this.loading = false;
				});
		},
		getBlogBanner() {
			this.$store
				.dispatch("customer/getBlogBanner", {
					company_id: this.company_id,
				})
				.then((res) => {
					if (res.data.show_banner === true) {
						this.pinToTopPost = res.data.data;
						this.isPinTop = true;
					} else {
						this.isPinTop = false;
					}
				})
				.catch((_) => {
					this.isPinTop = false;
				});
		},
		getBlogList(id) {
			this.loading = true;
			this.$store
				.dispatch("customer/getBlog", id)
				.then((_) => {
					this.loading = false;
				})
				.catch((_) => {
					this.loading = false;
				});
		},
		onSearchBlog: debounce(function () {
			if (this.searchValue) {
				const pageUrl = `?company_id=${this.company_id}&limit=${this.limit}&search=${this.searchValue}`;
				this.$store
					.dispatch("customer/getBlogs", { url: pageUrl })
					.then((_) => {
						this.loading = false;
					})
					.catch((_) => {
						this.loading = false;
					});
			} else {
				this.getBlogsList(this.company_id);
			}
		}, 300),
		getBlogsList(id) {
			this.loading = true;
			const pageUrl = `?company_id=${id}&limit=${this.limit}`;
			this.$store
				.dispatch("customer/getBlogs", { url: pageUrl })
				.then((_) => {
					this.loading = false;
				})
				.catch((_) => {
					this.loading = false;
				});
		},
		getBlogCategory(data) {
			this.$store.dispatch("customer/getBlogCategory", { id: data });
		},
		getBlogArchive(id) {
			this.$store.dispatch("customer/getArchives", {
				url: `?company_id=${id}`,
			});
		},
		truncate: function (text, length, suffix) {
			if (text.length > length) {
				return text.substring(0, length) + suffix;
			} else {
				return text;
			}
		},
		toggleMore(status) {
			this.more = status;
		},
		blogFilter(status, value) {
			let pageUrl = `?company_id=${this.company_id}&limit=${this.limit}`;
			this.loading = true;
			this.stateValue = status;
			if (status === "category") {
				pageUrl = pageUrl + `&category=${value}`;
				this.filterValue = value;
			} else if (status === "month") {
				pageUrl =
					pageUrl +
					`&date_from=${value}&date_to=${this.calculateMonthEnd(
						value
					)}`;
				this.filterValue = value;
			}
			this.$store
				.dispatch("customer/getBlogs", { url: pageUrl })
				.then((_) => {
					this.loading = false;
				})
				.catch((_) => {
					this.loading = false;
				});
		},
		calculateMonthEnd(date) {
			const currDate = this.$services.moment(date);
			const daysInMonth = this.$services
				.moment(currDate, "YYYY-MM-DD")
				.daysInMonth();
			const splitter = date.split("-");
			return `${splitter[0]}-${splitter[1]}-${daysInMonth}`;
		},
		cleanMessage(message) {
			return message
		},
		redirectUri: function (url) {
			this.$router.replace(url);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #f167ca !important;
	border-color: #f167ca !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
		0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
		0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
		0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all 0.2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2cdca7 !important;
	border-color: #2cdca7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table th {
	// padding: 0.1rem 0.55rem !important;
	color: #000000 !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
}

.form-select {
	border: 1px solid #dfe0eb !important;
	height: unset !important;
	padding: 0.175rem 1.5rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.table-striped tbody:nth-of-type(2n + 1) {
	background-color: rgba(0, 0, 0, 0.03);
}

.c-border {
	border-width: 1px !important;
}

.blog_title {
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	/* identical to box height */

	letter-spacing: 0.3px;
	height: 73px;
	color: #000000;
	padding: 14px !important;
	font-size: 12px;
	line-height: 15px;
}

.blog-content {
	overflow-wrap: break-word;
	word-break: break-all;
	padding: 0px 26px 10px 26px;
	font-size: 14px;
	color: #a4a6b3 !important;
}

._blog_btn {
	margin: auto;
	padding: 80px;
	background: #181c4c;
	text-decoration: none;
}

.mr-6 {
	margin-right: 6px;
}

.cursor-pointer {
	cursor: pointer;
}

.show-more-less {
	font-style: italic;
	font-weight: 600;
}

.h-200 {
	height: 200px !important;
}

.blog-card {
	height: 410px !important;
}
</style>
